<template>
  <div>
    <nav id="nav" class="navbar navbar-expand-xl">
      <button id="navToggler" class="navbar-toggler bg-dark navbar-dark" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse center" id="collapsibleNavbar">
        <ul class="navbar-nav left-align col-sm">
          <li id="topNavMargin" class="nav-item home">
            <router-link class="nav-link" to="/">
              <i class="fa fa-home"></i> Home
            </router-link>
          </li>
          <li class="nav-item about">
            <router-link class="nav-link" to="/About">
              <i class="fa-solid fa-book"></i> About
            </router-link>
          </li>
          <li class="nav-item resume">
            <router-link class="nav-link" to="/Resume">
              <i class="fa-solid fa-list"></i> Resumé
            </router-link>
          </li>
          <li class="nav-item portfolio">
            <router-link class="nav-link" to="/Portfolio">
              <i class="fa-solid fa-folder"></i> Portfolio
            </router-link>
          </li>
          <li class="nav-item contact">
            <router-link class="nav-link" to="/Contact">
              <i class="fa-solid fa-handshake"></i> Contact
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  Navbar: 'Navbar'
}
</script>
<style lang="scss">
.home .router-link-exact-active { 
  background-color: #c42f24;
}
.about .router-link-exact-active {
  background-color: #e7cd39;
}
.resume .router-link-exact-active {
  background-color: #9ad867;
}
.portfolio .router-link-exact-active {
  background-color: #64a6db;
}
.contact .router-link-exact-active {
  background-color: #bc6bdb;
}
.me .router-link-exact-active {
  background-color: #534a83;
}
.btn-check:focus+.btn, .btn:focus {
  outline: none !important; 
  box-shadow: none !important;
}
.navbar-toggler:focus {
  outline: none !important; 
  box-shadow: none !important;
}
.nav-item {
  padding: none;
	display: inline-block;
	text-decoration: none; 
	transition: all 0.25s;
	background-size: 100% 200%;
	&.home {
    background-image: linear-gradient(to top, #dd372c 50%, transparent 50%);
    min-width: 200px;
  }
  &.about {
    background-image: linear-gradient(to top, #c7ae20 50%, transparent 50%);
    min-width: 200px;
  }
  &.resume {
    background-image: linear-gradient(to top, #71ad40 50%, transparent 50%);
    min-width: 200px;
  }
  &.portfolio {
    background-image: linear-gradient(to top, #4484b8 50%, transparent 50%);
    min-width: 200px;
  }
  &.contact {
    background-image: linear-gradient(to top, #9046ad 50%, transparent 50%);
    min-width: 200px;
  }
  &.me {
    background-image: linear-gradient(to top, #2d2653 50%, transparent 50%);
    min-width: 200px;
  }
  &:hover {
    background-position: 0 100%; 
	  color: #fff;
  }
}
.nav-link {
  color: white;
    &:hover, &:focus { 
      color: white;
    cursor: pointer;
  }
}
#login {
  text-align: left;
}
#nav {
  background-color: #0e0c0c;
  border-bottom: 2px solid #2b2b2b;
  a {
    color: white;
    &.router-link-exact-active {
      font-weight: bold;
      color: black;
    }
  }
}
#navToggler {
  padding: 10px 10px 10px 10px;
  margin: 5px 15px 5px 15px;
}
@media only screen and (max-width: 1199px) {
  #login {
    margin-bottom: -10px;
  }
  #topNavMargin {
    margin-top: 10px;
  }
  div {
    ul {
      li { 
        i:first-child {
          padding-left: 42%;
        }
      }
    }
    #collapsibleNavbar2 {
      a {
        padding-left:42%;
      }
      width: 100%;
    }
  }
}
@media only screen and (min-width: 1199px) {
  #nav {
    height: 41px;
  }
  .nav-item {
    min-width: 125px;
  }
  div {
    ul {
      li { 
        margin: -10px 0px -10px 0px;
      }
    }
  }
}
</style>
