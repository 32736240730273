import { render, staticRenderFns } from "./Resume.vue?vue&type=template&id=0f5b5424&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5b5424",
  null
  
)

export default component.exports