<template>
  <div id="app">
    <Navbar />
    <router-view/>
  </div>
</template>
<script>
  import Navbar from './components/Navbar';

  export default {
    name: 'app',
    components: {
      Navbar,
    },
    // computed: {
    //   isPC() {
    //     return this.$route.path === '/PC';
    //   }
    // },
    
  }
</script>

<style lang="scss">
// https://codepen.io/burntcustard/pen/PwGgJz
// https://www.w3schools.com/howto/howto_js_full_page_tabs.asp
// https://www.w3schools.com/howto/howto_js_sidenav.asp
// https://www.w3schools.com/bootstrap5/bootstrap_flex.php
// https://codepen.io/adammacias/pen/dozPVQ

/* Remove user agent styling */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body {
  height:100%;
  background-color: black;
}
body {
  margin: 0; /*reset default margin*/
  background-color: black;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* *********************************** */
// Elements
div {
  .homeborder {
    border: solid 2px #c42f24;
  }
  .aboutborder {
    border: solid 2px #c7ae20;
  }
  .resumeborder {
    border: solid 2px #71ad40;
  }
  .portfolioborder {
    border: solid 2px #4484b8;
  }
  .contactborder {
    border: solid 2px #9046ad;
  }
  .homeborder, .aboutborder, .contactborder, .resumeborder, .portfolioborder {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    background-color: #080808;
  }
}
p {
  line-height: 1.3 !important;
}
/* *********************************** */
// Classes

.bold {
  font-weight: bold;
}
.button {
  background-color: #080808;
  float: none;
  border: none;
  border-radius: 15px;
  margin-bottom: 10px;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.center {
  text-align: center;
}
.centerImage {
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  border-radius: 10px;
}
.cursor {
  cursor: pointer;
}
.flex-container {
  justify-content: space-around;
}
.grid-container {
  display: grid;
  gap: 20px;
}
.setting-grid-container {
  display: grid;
  row-gap: 0px;
  column-gap: 10px;
}
.inline {
  display: inline;
}
.left-align {
  text-align: left;
}
.link {
  text-decoration: none;
  color: white;
  font-weight: bold;
}
.round {
  border-radius: 10px;
}
.sideMargins {
  margin-left: 5%;
  margin-right: 5%;
}
.spacing {
  margin-bottom: 10px;
  margin-top: 10px;
}
/* *********************************** */
// IDs
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: lightgrey;
  background-color: black;
  height:100%;
  background-color: black;
}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
// Viewports
/* Extra Small */
@media screen and (max-width: 500px) {
  
}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* Small */
@media screen and (max-width: 768px) {
  
}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
@media screen and (min-width: 768px) {
  
}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
// Medium
@media screen and (min-width: 768px) and (max-width: 900px) {
  
}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
// Large
@media only screen and (min-width: 900px) {

}
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
/* *********************************** */
// Extra Large
@media only screen and (min-width: 1199px) {
  div ul li {
    margin: 0px;
  }
  .hideScroller::-webkit-scrollbar {
    display: none;
  }
}
/* *********************************** */
// Scrollbars
// https://itnext.io/dark-and-light-scrollbar-at-runtime-with-vue-and-vuetify-3809076e547d
// https://stackoverflow.com/questions/42872002/in-vue-js-component-how-to-use-props-in-css
// https://vuejs.org/guide/components/props.html
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 15px;
}
::-webkit-scrollbar-track {
  background: black;
}
.hideScroller::-webkit-scrollbar {
  display: none;
}
</style>
