import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import About from '../views/About.vue';
import Resume from '../views/Resume.vue';
import Portfolio from '../views/Portfolio.vue';
import Contact from '../views/Contact.vue';

// import { isAuthenticated } from './auth.js';

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/DM',
  //   component: DM,
  //   name: 'DM',
  //   beforeEnter: (to, from, next) => {
  //     if (isAuthenticated()) {
  //       next();
  //     } else {
  //       next('/Login');
  //     }
  //   },
  // },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/About',
    name: 'About',
    component: About,
    component: () => import('../views/About.vue')
  },
  {
    path: '/Resume',
    name: 'Resume',
    component: Resume
  },
  {
    path: '/Portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  // history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
